import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AuthenticationRoutes from './AuthenticationRoutes';
// import Expenses from 'views/pages/Expenses';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard')));

// pages routing

// project and product
const ProjectOwner = Loadable(
  lazy(() => import('views/pages/ProjectProduct/ProjectOwner'))
);
const Projects = Loadable(
  lazy(() => import('views/pages/ProjectProduct/Projects'))
);
const SingleProjectView = Loadable(
  lazy(() => import('views/pages/ProjectProduct/Projects/SingleProjectView'))
);
const ProjectInfo = Loadable(
  lazy(() =>
    import('views/pages/ProjectProduct/Projects/SingleProjectView/ProjectInfo')
  )
);
const ProjectRent = Loadable(
  lazy(() =>
    import('views/pages/ProjectProduct/Projects/SingleProjectView/ProjectRent')
  )
);
const ProjectSummary = Loadable(
  lazy(() =>
    import(
      'views/pages/ProjectProduct/Projects/SingleProjectView/ProjectSummary'
    )
  )
);
const Uom = Loadable(lazy(() => import('views/pages/ProjectProduct/Uom')));
const Products = Loadable(
  lazy(() => import('views/pages/ProjectProduct/Products'))
);

// office management
const Employees = Loadable(
  lazy(() => import('views/pages/OfficeManagement/Employees'))
);
const Salaries = Loadable(
  lazy(() => import('views/pages/OfficeManagement/Salaries'))
);
const UtilityBill = Loadable(
  lazy(() => import('views/pages/OfficeManagement/UtilityBill'))
);
const UtilityBillHome = Loadable(
  lazy(() => import('views/pages/OfficeManagement/UtilityBill/UtilityBillHome'))
);
const UtilityList = Loadable(
  lazy(() => import('views/pages/OfficeManagement/UtilityBill/UtilityList'))
);

// expense management
const Vendors = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/Vendors'))
);
const ExpenseHeads = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/ExpenseHeads'))
);
const OperationExpenses = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/OperationExpenses'))
);
const OperationExpenseHome = Loadable(
  lazy(() =>
    import(
      'views/pages/ExpenseManagement/OperationExpenses/OperationExpenseHome'
    )
  )
);
const TabExpenses = Loadable(
  lazy(() =>
    import('views/pages/ExpenseManagement/OperationExpenses/TabExpenses')
  )
);
const ProductionExpenses = Loadable(
  lazy(() => import('views/pages/ExpenseManagement/ProductionExpenses'))
);
const ProductionExpenseHome = Loadable(
  lazy(() =>
    import(
      'views/pages/ExpenseManagement/ProductionExpenses/ProductionExpenseHome'
    )
  )
);
const ProductionTabExpenses = Loadable(
  lazy(() =>
    import(
      'views/pages/ExpenseManagement/ProductionExpenses/ProductionTabExpenses'
    )
  )
);

// purchase management
const ProductPurchase = Loadable(
  lazy(() => import('views/pages/PurchaseManagement/ProductPurchase'))
);

// income management
const Customers = Loadable(
  lazy(() => import('views/pages/IncomeManagement/Customers'))
);
const IncomeHeads = Loadable(
  lazy(() => import('views/pages/IncomeManagement/IncomeHeads'))
);
const Incomes = Loadable(
  lazy(() => import('views/pages/IncomeManagement/Incomes'))
);

// financial
const ReceivePayment = Loadable(
  lazy(() => import('views/pages/Financial/ReceivePayment'))
);
const MakePayment = Loadable(
  lazy(() => import('views/pages/Financial/MakePayment'))
);
const FixedAsset = Loadable(
  lazy(() => import('views/pages/Financial/FixedAsset'))
);
const FixedAssetHome = Loadable(
  lazy(() => import('views/pages/Financial/FixedAsset/FixedAssetHome'))
);
const Depreciation = Loadable(
  lazy(() => import('views/pages/Financial/FixedAsset/Depreciation'))
);
const SoldAssets = Loadable(
  lazy(() => import('views/pages/Financial/FixedAsset/SoldAssets'))
);
const DamagedAssets = Loadable(
  lazy(() => import('views/pages/Financial/FixedAsset/DamagedAssets'))
);
const AssetList = Loadable(
  lazy(() => import('views/pages/Financial/FixedAsset/AssetList'))
);
const ViewFixedAsset = Loadable(
  lazy(() =>
    import('views/pages/Financial/FixedAsset/FixedAssetHome/ViewFixedAsset')
  )
);
const FixedAssetCosts = Loadable(
  lazy(() =>
    import(
      'views/pages/Financial/FixedAsset/FixedAssetHome/ViewFixedAsset/FixedAssetCosts'
    )
  )
);
const FixedAssetDetailsSummary = Loadable(
  lazy(() =>
    import(
      'views/pages/Financial/FixedAsset/FixedAssetHome/ViewFixedAsset/FixedAssetDetailsSummary'
    )
  )
);
const Investment = Loadable(
  lazy(() => import('views/pages/Financial/Investment'))
);
const Withdraw = Loadable(lazy(() => import('views/pages/Financial/Withdraw')));
const AccountSummary = Loadable(
  lazy(() => import('views/pages/Financial/AccountSummary'))
);

// analysis and costing
const AnalysisList = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/AnalysisList'))
);
const FishSize = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/FishSize'))
);
const AnalysisSummary = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/AnalysisSummary'))
);

// single analysis
const SingleAnalysisView = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/SingleAnalysisView'))
);
const SingleAnalysisHome = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/SingleAnalysisView/SingleAnalysisHome'))
);
const FishSales = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/SingleAnalysisView/FishSales'))
);
const FoodExpenses = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/SingleAnalysisView/FoodExpenses'))
);
const OtherExpenses = Loadable(
  lazy(() => import('views/pages//AnalysisAndCosting/SingleAnalysisView/OtherExpenses'))
);

// report
const ReportSummary = Loadable(
  lazy(() => import('views/pages/Report/ReportSummary'))
);
const DueReport = Loadable(lazy(() => import('views/pages/Report/DueReport')));
const Payable = Loadable(
  lazy(() => import('views/pages/Report/DueReport/Payable'))
);
const Receivable = Loadable(
  lazy(() => import('views/pages/Report/DueReport/Receivable'))
);
const ExpenseSummary = Loadable(
  lazy(() => import('views/pages/Report/ExpenseSummary'))
);

// single expense details
const SingleExpenseDetail = Loadable(
  lazy(() => import('views/pages/Report/ExpenseSummary/SingleExpenseDetail'))
);

// utilities routing

// setting routing
const ManageUser = Loadable(lazy(() => import('views/setting/ManageUser')));
const SingleUser = Loadable(
  lazy(() => import('views/setting/ManageUser/SingleUser'))
);
const UserInfo = Loadable(
  lazy(() => import('views/setting/ManageUser/SingleUser/UserInfo'))
);
const UserPermission = Loadable(
  lazy(() => import('views/setting/ManageUser/SingleUser/UserPermission'))
);
const FisheriesYear = Loadable(
  lazy(() => import('views/setting/FisheriesYear'))
);

// support routing
const Support = Loadable(lazy(() => import('views/support')));

// Support error routing
const Error404 = Loadable(lazy(() => import('views/Error404')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <PrivateRoute />,
  children: [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        {
          path: '/',
          element: <Navigate to="dashboard" replace={true} />,
        },
        {
          path: 'dashboard',
          element: <Navigate to="default" replace={true} />,
        },
        {
          path: 'dashboard/default',
          element: <DashboardDefault />,
        },
        {
          path: 'pages',
          children: [
            {
              path: 'project-product',
              children: [
                {
                  path: 'land-owner',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['land-owner']}
                    >
                      <ProjectOwner />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'project',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['project']}
                    >
                      <Projects />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'project/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['project']}
                    >
                      <SingleProjectView />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <ProjectInfo />,
                    },
                    {
                      path: 'project-rent',
                      element: <ProjectRent />,
                    },
                    {
                      path: 'summary',
                      element: <ProjectSummary />,
                    },
                  ],
                },
                {
                  path: 'uom',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['uom']}
                    >
                      <Uom />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'product',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['product']}
                    >
                      <Products />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'office-management',
              children: [
                {
                  path: 'employees',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['employees']}
                    >
                      <Employees />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'salaries',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['salaries']}
                    >
                      <Salaries />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'utility-bill',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['utility-bill']}
                    >
                      <UtilityBill />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <UtilityBillHome />,
                    },
                    {
                      path: 'utility-list',
                      element: <UtilityList />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'expense-management',
              children: [
                {
                  path: 'vendors',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['vendors']}
                    >
                      <Vendors />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'expense-heads',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['expense-heads']}
                    >
                      <ExpenseHeads />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'operation-expense',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['operation-expense']}
                    >
                      <OperationExpenses />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <OperationExpenseHome />,
                    },
                    {
                      path: ':id',
                      element: <TabExpenses />,
                    },
                  ],
                },
                {
                  path: 'production-expense',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['production-expense']}
                    >
                      <ProductionExpenses />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <ProductionExpenseHome />,
                    },
                    {
                      path: ':id',
                      element: <ProductionTabExpenses />,
                    },
                  ],
                },
              ],
            },
            {
              path: 'purchase-management',
              children: [
                {
                  path: 'purchase',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['purchase']}
                    >
                      <ProductPurchase />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'income-management',
              children: [
                {
                  path: 'customers',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['customers']}
                    >
                      <Customers />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'income-heads',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['income-Heads']}
                    >
                      <IncomeHeads />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'incomes',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['incomes']}
                    >
                      <Incomes />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'financial',
              children: [
                {
                  path: 'make-payment',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['make-payment']}
                    >
                      <MakePayment />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'receive-payment',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['receive-payment']}
                    >
                      <ReceivePayment />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'fixed-asset',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['fixed-asset']}
                    >
                      <FixedAsset />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <FixedAssetHome />,
                    },
                    {
                      path: 'depreciation',
                      element: <Depreciation />,
                    },
                    {
                      path: 'sold-asset',
                      element: <SoldAssets />,
                    },
                    {
                      path: 'damaged-asset',
                      element: <DamagedAssets />,
                    },
                    {
                      path: 'asset-list',
                      element: <AssetList />,
                    },
                  ],
                },
                {
                  path: 'fixed-asset/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['fixed-asset']}
                    >
                      <ViewFixedAsset />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <FixedAssetCosts />,
                    },
                    {
                      path: 'summary',
                      element: <FixedAssetDetailsSummary />,
                    },
                  ],
                },
                {
                  path: 'investment',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['investment']}
                    >
                      <Investment />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'withdraw',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['withdraw']}
                    >
                      <Withdraw />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'account-summary',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['account-summary']}
                    >
                      <AccountSummary />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'analysis-costing',
              children: [
                {
                  path: 'analysis-list',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['analysis-list']}
                    >
                      <AnalysisList />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'analysis-list/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['analysis-list']}
                    >
                      <SingleAnalysisView />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <SingleAnalysisHome />,
                    },
                    {
                      path: 'fish-sales',
                      element: <FishSales />,
                    },
                    {
                      path: 'food-expenses',
                      element: <FoodExpenses />,
                    },
                    {
                      path: 'other-expenses',
                      element: <OtherExpenses />,
                    },
                  ],
                },
                {
                  path: 'fish-size',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['fish-size']}
                    >
                      <FishSize />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'analysis-summary',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['analysis-summary']}
                    >
                      <AnalysisSummary />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'report',
              children: [
                {
                  path: 'summary',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['summary']}
                    >
                      <ReportSummary />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'due-report',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['due-report']}
                    >
                      <DueReport />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <Payable />,
                    },
                    {
                      path: 'receivable',
                      element: <Receivable />,
                    },
                  ],
                },
                {
                  path: 'expense-summary',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['expense-summary']}
                    >
                      <ExpenseSummary />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'expense-summary/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['expense-summary']}
                    >
                      <SingleExpenseDetail />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'utils',
          children: [
            {
              path: 'setting',
              children: [
                {
                  path: 'manage-user',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['manage-user']}
                    >
                      <ManageUser />
                    </AuthenticationRoutes>
                  ),
                },
                {
                  path: 'manage-user/:id',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['manage-user']}
                    >
                      <SingleUser />
                    </AuthenticationRoutes>
                  ),
                  children: [
                    {
                      path: '',
                      element: <UserInfo />,
                    },
                    {
                      path: 'permission',
                      element: <UserPermission />,
                    },
                  ],
                },
                {
                  path: 'fisheries-year',
                  element: (
                    <AuthenticationRoutes
                      allowedRoles={['super_admin', 'admin']}
                      allowedCodes={['fisheries-year']}
                    >
                      <FisheriesYear />
                    </AuthenticationRoutes>
                  ),
                },
              ],
            },
            {
              path: 'support',
              element: <Support />,
            },
          ],
        },
        {
          path: '/*',
          element: <Error404 />,
        },
      ],
    },
  ],
};

export default MainRoutes;
