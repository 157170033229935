import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from 'api/client';
import { axiosBaseQuery } from 'helper/axios/axiosBaseQuery';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseUrl: BASE_URL,
  }),
  tagTypes: [
    'auth',
    'user',
    'project',
    'uom',
    'product',
    'vendor',
    'expenseHead',
    'expense',
    'customer',
    'incomeHead',
    'income',
    'asset',
    'fixedAsset',
    'voucher',
    'investment',
    'withdraw',
    'productionYear',
    'analysis',
    'fishSize',
    'analysisSale',
    'analysisCost',
  ],
  endpoints: () => ({}),
});
